

import React, { useEffect } from "react";
import history from './../../../history';

interface Props {
  children: any
}
const GoogleTagManagerRefresh: React.FC<Props> = (props: Props) => {
  const { children } = props

  useEffect(() => {
    history.listen(
      () => {
        if (window.location.hostname !== 'localhost' && window.location.hostname !== 'qa.suggestly.io') {
          const gtag = (window as any).gtag;
          gtag('config', 'UA-143705223-13', {
            'page_location': window.location.href,
            'page_path': window.location.pathname,
          })
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])
  // eslint-enable-next-line react-hooks/exhaustive-deps

  return (
    <>
      {children}
    </>
  )
}

export default GoogleTagManagerRefresh;
