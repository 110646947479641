import React, { useEffect, useRef } from "react";
import "./blog-page.scss";
import { RouteComponentProps, withRouter } from "react-router-dom";
declare var suggestlyWidgetContainer: any;

const KawaButton: React.FC<any> = (props: any & RouteComponentProps) => {
  const widgetContainerRef = useRef<any>();

  useEffect(() => {

    let suggestlyRootWidgetElem = document.getElementById('suggestlyRootWidget');
    if (suggestlyRootWidgetElem) {
      suggestlyRootWidgetElem.remove();
      suggestlyRootWidgetElem = null;
    }

    const embedCode =
      `(function (w, d, s, o, f, js, fjs) {
      w['Suggestly-Widget'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1;
      fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'suggestlyWidgetContainer', 'https://api.suggestly.io/script/widget.js'));
      suggestlyWidgetContainer('init', { targetElementId: 'suggestlyRootWidget', se_id: '${props.paramSuggId}' });`;

    let suggestlyScriptRoot = document.getElementById('suggestlyScriptRoot');
    if (suggestlyScriptRoot && props.paramSuggId) {
      (suggestlyScriptRoot as HTMLScriptElement).text = embedCode;
      suggestlyWidgetContainer('init', { targetElementId: 'suggestlyRootWidget', se_id: `${props.paramSuggId}` });
    }

    return () => {
      let suggestlyScriptRoot = document.getElementById('suggestlyScriptRoot');
      if (suggestlyScriptRoot) {
        (suggestlyScriptRoot as HTMLScriptElement).text = '';
      }
      var oldElem = document.getElementById('suggestlyWidgetContainer');
      if (oldElem) {
        oldElem.remove();
        oldElem = null;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paramSuggId])


  const handleLiveButton = () => {
    suggestlyWidgetContainer('openwidget', true);
  }

  return (
    <div className={"liveButtonWrapper"}>
      <div ref={widgetContainerRef}></div>
      <div className="liveButton" onClick={handleLiveButton}>
        <i className="fa fa-cube" area-hidden="true"></i> &nbsp;Uruchom Wirtualnego Asystenta!
      </div>
    </div >
  )
}

export default withRouter(KawaButton);