import React, { useEffect, useState } from "react";
import "./preview-page.scss";
import { GetParamsHelper } from "../../tools/PathParamsHelper";
import { useSelector, shallowEqual } from "react-redux";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import LiveButton from "../../components/navbar-top/live-button";

const PreviewPage: React.FC<any> = () => {
  const [paramSuggestlyId, setParamSuggestlyId] = useState('')

  useEffect(() => {
    let params = GetParamsHelper();
    let suggestlyId = params.find((x) => x.name === 'paramSuggestlyId')!.value;
    setParamSuggestlyId(suggestlyId);
  }, [])

  const currentUser = useSelector(selectCurrentUser, shallowEqual);

  return (
    <div>
      {paramSuggestlyId ? <LiveButton isPanelSwitch={true} isDisabled={(currentUser && currentUser.isSignUpTutorialActive)} paramSuggId={paramSuggestlyId} /> : null }
    </div>
  )
}

export default PreviewPage;