import { ApiMethodDto, UrlParam } from "./api-method-dto";
import { ApiPath } from "./apiPath";
import { callApi } from "./api-call-service";
import { UserDto } from "../dto/user-dto";
import { store } from "../redux/store";
import { SimulationOutputResultDto } from "../dto/simulationOutputResultDto";

export class SimulationController {
  static async run(suggestlyEntityId: string, currentIterationsCount: number): Promise<Array<SimulationOutputResultDto>> {
    return new Promise(async (resolve) => {
      const token = (store.getState().user as UserDto).token;

      const options = {
        headers: { Authorization: token },
        method: "GET",
        params: [
          { name: 'suggestlyEntityId', value: suggestlyEntityId },
          { name: 'currentIterationsCount', value: currentIterationsCount }
        ] as Array<UrlParam>,
        expectedStatus: 200,
      } as ApiMethodDto;

      await callApi(options.method, ApiPath.simulation_run, options)
        .then((res: any) => {
          if (res.details.status === options.expectedStatus) {
            // store.dispatch({
            //   type: UserActionTypes.SET_ISWAITINGFORNEWROLESACTIVE_SUCCESS,
            //   payload: { isActive: true, text: "Twoje konto jest aktualizowane" }
            // });
            resolve(res.data);
          }
        })
    });
  }
}