import { ApiRootPath } from "./apiRootPath";

const rootPath: string = ApiRootPath.rootPath;
export class ApiPath {
  // AUTH
  static auth_signUp: string = rootPath + 'auth/signUp';
  static auth_signIn: string = rootPath + 'auth/signIn';
  static auth_signOut: string = rootPath + 'auth/signOut';
  static auth_refreshToken: string = rootPath + 'auth/refreshToken';
  static auth_resetPassword: string = rootPath + 'auth/resetPassword';
  static auth_getUserRoles: string = rootPath + 'auth/getUserRoles';
  static auth_sendMessageToSupport: string = rootPath + 'auth/sendMessageToSupport';

  // CATEGORY
  static input_addInput: string = rootPath + 'input/addInput';
  static input_getInput: string = rootPath + 'input/getInput';
  static input_deleteInput: string = rootPath + 'input/deleteInput';

  static input_updateInputImage: string = rootPath + 'input/updateInputImage';
  static input_updateInputName: string = rootPath + 'input/updateInputName';
  static input_updateInputTitle: string = rootPath + 'input/updateInputTitle';
  static input_updateInputDescription: string = rootPath + 'input/updateInputDescription';

  static input_addVariant: string = rootPath + 'input/addVariant';
  static input_deleteVariant: string = rootPath + 'input/deleteVariant';
  static input_updateVariant: string = rootPath + 'input/updateVariant';
  static input_updateVariantTitle: string = rootPath + 'input/updateVariantTitle';
  static input_getAllInputs: string = rootPath + 'input/getAllInputs';

  // PRODUCT
  static output_addOutput: string = rootPath + 'output/addOutput';
  static output_getOutput: string = rootPath + 'output/getOutput';
  static output_deleteOutput: string = rootPath + 'output/deleteOutput';
  static output_getAllOutputs: string = rootPath + 'output/getAllOutputs';
  static output_updateOutputName: string = rootPath + 'output/updateOutputName';

  static output_updateProductImage: string = rootPath + 'output/updateProductImage';
  static output_updateProductTitle: string = rootPath + 'output/updateProductTitle';
  static output_updateProductSubtitle: string = rootPath + 'output/updateProductSubtitle';
  static output_updateProductDescription: string = rootPath + 'output/updateProductDescription';
  static output_updateProductReffLink: string = rootPath + 'output/updateProductReffLink';
  static output_updateProductPrice: string = rootPath + 'output/updateProductPrice';
  static output_updateProductHeader: string = rootPath + 'output/updateProductHeader';
  static output_updateProductActionButtonLabel: string = rootPath + 'output/updateProductActionButtonLabel';

  static output_updateMaillistgrowthoutputTitle: string = rootPath + 'output/updateMaillistgrowthoutputTitle';
  static output_updateMaillistgrowthoutputDescription: string = rootPath + 'output/updateMaillistgrowthoutputDescription';
  static output_updateMaillistgrowthoutputMessage: string = rootPath + 'output/updateMaillistgrowthoutputMessage';
  static output_updateMaillistgrowthoutputHeader: string = rootPath + 'output/updateMaillistgrowthoutputHeader';
  static output_updateMaillistgrowthoutputAcceptLabel: string = rootPath + 'output/updateMaillistgrowthoutputAcceptLabel';

  static output_updateLeadmagnetoutputTitle: string = rootPath + 'output/updateLeadmagnetoutputTitle';
  static output_updateLeadmagnetoutputDescription: string = rootPath + 'output/updateLeadmagnetoutputDescription';
  static output_updateLeadmagnetoutputMessage: string = rootPath + 'output/updateLeadmagnetoutputMessage';
  static output_updateLeadmagnetoutputHeader: string = rootPath + 'output/updateLeadmagnetoutputHeader';
  static output_updateLeadmagnetoutputAcceptLabel: string = rootPath + 'output/UpdateLeadmagnetoutputAcceptLabel';

  // WAGES
  static wages_updateWage: string = rootPath + 'wages/updateWage';
  static wages_getAllInputsWages: string = rootPath + 'wages/getAllInputsWages';

  // ACCOUNT SETTINGS
  static accountSettings_getBillingInformations: string = rootPath + 'accountSettings/getBillingInformations';
  static accountSettings_updateBillingField: string = rootPath + 'accountSettings/updateBillingField';
  static accountSettings_getNotifications: string = rootPath + 'accountSettings/getNotifications';
  static accountSettings_changeNotifications: string = rootPath + 'accountSettings/changeNotifications';
  static accountSettings_changePassword: string = rootPath + 'accountSettings/changePassword';
  static accountSettings_sendMessageToSupport: string = rootPath + 'accountSettings/sendMessageToSupport';

  // SUGGESTLY ENTITY
  static suggestlyEntity_getAllSuggestlyEntities: string = rootPath + 'suggestlyEntity/getAllSuggestlyEntities';
  static suggestlyEntity_createSuggestlyEntity: string = rootPath + 'suggestlyEntity/createSuggestlyEntity';
  static suggestlyEntity_deleteSuggestlyEntity: string = rootPath + 'suggestlyEntity/deleteSuggestlyEntity';
  static suggestlyEntity_getClientActionsMonthly: string = rootPath + 'suggestlyEntity/getClientActionsMonthly';
  static suggestlyEntity_renameSuggestlyEntity: string = rootPath + 'suggestlyEntity/renameSuggestlyEntity';

  // SIMULATION
  static simulation_run: string = rootPath + 'simulation/run';


  // EMBED
  static embed_getEmbedDomains: string = rootPath + 'embed/getEmbedDomains';
  static embed_deleteEmbedDomain: string = rootPath + 'embed/deleteEmbedDomain';
  static embed_addEmbedDomain: string = rootPath + 'embed/addEmbedDomain';

  // LEAD
  static lead_deleteLead: string = rootPath + 'lead/deleteLead';
  static lead_getAllLeads: string = rootPath + 'lead/getAllLeads';

  // PAYMENT
  static payment_subscribe: string = rootPath + 'payment/subscribe';
  static payment_updatePlan: string = rootPath + 'payment/updatePlan';
  static payment_updateCard: string = rootPath + 'payment/updateCard';
  static payment_unsubscribe: string = rootPath + 'payment/unsubscribe';
  static payment_realizeCoupon: string = rootPath + 'payment/realizeCoupon';

  // PUBLIC
  static publicApi_confirmEmail: string = rootPath + 'publicApi/confirmEmail';
  static publicApi_isLive: string = rootPath + 'publicApi/isLive';
}