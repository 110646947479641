/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import "./home-page.scss";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";

import NavbarLanding from "../../components/navbar-landing/navbar-landing";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainImg from "./assets/mainImg.svg";
import ArrowJump from "./assets/arrowJump.svg";
import AnimA from "./assets/animA.mp4";
import AnimB from "./assets/animB.mp4";

import ArrowDownA from "./assets/arrowDownA.svg";
import ArrowDownB from "./assets/arrowDownB.svg";
import StepA from "./assets/defineQuestions.svg";
import StepB from "./assets/defineProducts.svg";
import StepC from "./assets/helpClients.svg";
// import TrustLogo from "./assets/trustLogo.svg";

import Relation from "./assets/relation.svg";
import ArrowDownC from "./assets/arrowDownC.svg";

import WantToBack from "./assets/wantToBack.svg";
import WorthContacts from "./assets/worthContacts.svg";
import PositivMarketing from "./assets/positivMarketing.svg";

import FreeStart from "./assets/freeStart.svg";
import ArrowDownD from "./assets/arrowDownD.svg";

import MichalImg from "./assets/michal_kowalczyk_software_developer.png";

import LeftHand from "./assets/handLeft.svg";

import Twitter from "./assets/icon_tw.svg";
import Facebook from "./assets/icon_fb.svg";
import Linkedin from "./assets/icon_li.svg";

import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import PrincingPlansLanding from "../../components/princingPlansLanding/princing-plans-landing";
import { AuthController } from "../../api/authController";
import { ToastsStore } from "react-toasts";
import { useDispatch } from "react-redux";
import { setSignUpEmail } from "../../redux/landingPageState/landingPageState.actions";

const HomePage: React.FC<any> = () => {

  const startLink = useRef<any>();
  const integracjaLink = useRef<any>();
  const CennikLink = useRef<any>();
  const KontaktLink = useRef<any>();
  const videoARef = useRef<any>();
  const videoBRef = useRef<any>();

  const [disableSendButton, setDisableSendButton] = useState(false);
  const [emailA, setEmailA] = useState("");
  const [emailB, setEmailB] = useState("");
  const [emailC, setEmailC] = useState("");

  const [contactMessage, setContactMessage] = useState("");
  const [contactEmail, setContactEmail] = useState("");

  const dispatch = useDispatch();

  const handleKeyDownA = (e: any) => {
    if (e.key === 'Enter' && emailA !== "") {
      // dispatch(addSuggestlyEntity(email));
    }
  }

  const handleKeyDownB = (e: any) => {
    if (e.key === 'Enter' && emailB !== "") {
      // dispatch(addSuggestlyEntity(email));
    }
  }


  const handleKeyDownC = (e: any) => {
    if (e.key === 'Enter' && emailC !== "") {
      // dispatch(addSuggestlyEntity(email));

    }
  }

  // const { pathname, hash} = useLocation();

  // useEffect(() => {
  //    window.scrollTo(0, 0);
  // }, [pathname]);

  // useEffect(() => {
  //   page.current.scrollTo(0, 0);
  // }, [])

  useEffect(() => {
    // history.push(RoutePath.HOME);

    // videoBRef.current.play();
    // setInterval(function () {
    //   videoBRef.current.play();
    // }, 18000);

    // setTimeout(() => {
    //   videoARef.current.play();
    //   setInterval(function () {
    //     videoARef.current.play();
    //   }, 18000);
    // }, 6000);

    // videoBRef.current.play();
    // setInterval(function () {
    //   videoBRef.current.play();
    // }, 8000);

    //   videoARef.current.play();
    //   setInterval(function () {
    //     videoARef.current.play();
    //   }, 14000);





  }, [])

  const handleScrollToSection = (section: React.MutableRefObject<any>) => {
    window.scrollTo(0, section.current!.getBoundingClientRect().top + window.pageYOffset);
  }

  const sendMessageToSupport = () => {

    setDisableSendButton(true);
    window.setTimeout(() => {
      setDisableSendButton(false);
    }, 1000);

    if (contactEmail === null || contactEmail === '') {
      ToastsStore.error("Uzupełnij email");
    }

    var sendMessageToSupportPromise = AuthController.sendMessageToSupport(contactEmail, contactMessage)
    sendMessageToSupportPromise.then((a: any) => {
      setContactEmail("");
      setContactMessage("");
      ToastsStore.success("Wiadomość została wysłana.");
    }, reason => {
      ToastsStore.error("Wystąpił problem z połączeniem, spróbuj później.");
      console.error(reason); // Error!
    })

  }

  return (
    <div className="home-page">
      <Helmet>
        <title>{'Suggestly'}</title>
        {/* <meta name="description" content="Blog interaktywny. Praca kreatywna od kuchni, ukazana z różnych perspektyw. Programowanie, Design, Biznes, Seo, Teamwork, Strony internetowe, Aplikacje webowe i wiele innych." />
          <meta name="keywords" content="narzędzia,design,komunikacja,teamwork,responsive" /> */}
      </Helmet>

      <NavbarLanding>
        <span onClick={() => { handleScrollToSection(startLink) }}>
          Start
        </span>
        <span onClick={() => { handleScrollToSection(integracjaLink) }}>
          Integracja
        </span>
        <span onClick={() => { handleScrollToSection(CennikLink) }}>
          Cennik
        </span>
        <span onClick={() => { handleScrollToSection(KontaktLink) }}>
          Kontakt
        </span>
      </NavbarLanding>

      <div className="overlayLoader"></div>

      <section className="mainSection">
        <Container>
          <Row>
            <Col md={{ span: 4, offset: 1 }} className="mainSection__mainImgCol">
              <div className="box">
                <img src={MainImg} alt="Wirtualny Asystent" />
              </div>
            </Col>
            <Col md={{ span: 6, offset: 1 }} className="mainSection__descrCol">
              <div className="box">
                <h2>Wirtualny Asystent wspomagający decyzje zakupowe Twoich klientów</h2>
                <p>Suggestly to małe, ale bardzo praktyczne narzędzie o wielu zastosowaniach. Pozwala na przyjazną komunikację pomiędzy prowadzącymi strony internetowe, a ich odbiorcami.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="animSection">
        <Container>
          <img className="arrowJump" src={ArrowJump} alt="" />
          <Row>
            <Col xl={{ span: 6 }} className="col">
              <h3>ZAANGAŻUJ POPRZEZ PYTANIA</h3>
              <div className="box">
                {/* <h2>Animacja tymczasowa</h2> */}
                <div className="videoWrapper">
                  <video src={AnimB} ref={videoBRef} muted autoPlay loop />
                </div>
              </div>
            </Col>
            <Col xl={{ span: 6 }} className="col">
              <h3>ZYSKAJ NOWYCH KLIENTÓW</h3>
              <div className="box">
                <div className="videoWrapper">
                  {/* <h2>Animacja tymczasowa</h2> */}
                  <video src={AnimA} muted ref={videoARef} autoPlay loop />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section ref={startLink} className="createProjectSection">
        <Container>
          <div className="introBox">
            <h4>Stwórz swój pierwszy projekt</h4>
            <p>
              Dzięki Suggestly stworzysz projekty, które naturalnie pokierują uwagę użytkowników tam, gdzie najbardziej potrzebujesz. W ten sposób zbudujesz relację, dzięki której: skutecznie wypromujesz produkt, zaangażujesz swoich odbiorców do wzajemnej komunikacji i zwiększysz konwersję.
            </p>
          </div>
          <div className="stepsBox">
            <Row>
              <Col xl={{ span: 3, offset: 1 }} sm={{ span: 6, offset: 0, order: 1 }} xs={{ order: 2 }} className="col">
                <div className="stepImg">
                  <img src={StepA} alt="" />
                </div>
              </Col>
              <Col xl={{ span: 1, offset: 0 }} sm={{ span: 2, offset: 7, order: 2 }} xs={{ order: 1 }} className="col">
                <div className="stepNum">
                  1
                </div>
              </Col>
              <Col xl={{ span: 6, order: 3 }} xs={{ order: 3 }} className="col">
                <div className="stepDescr">
                  <h5>Zdefiniuj pytania</h5>
                  <p>Wybierz najczęściej powtarzające się pytania, które zadają Twoi klienci. Postaraj wczuć się w ich rolę, zastanów się jakie wątpliwości mogą mieć, aby potem móc je rozwiać.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 1, offset: 4 }} className="col">
                <div className="stepArrow">
                  <img src={ArrowDownA} alt="" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 3, offset: 1 }} sm={{ span: 6, offset: 0, order: 1 }} xs={{ order: 2 }} className="col">
                <div className="stepImg">
                  <img src={StepB} alt="" />
                </div>
              </Col>
              <Col xl={{ span: 1, offset: 0 }} sm={{ span: 2, offset: 7, order: 2 }} xs={{ order: 1 }} className="col">
                <div className="stepNum">
                  2
                </div>
              </Col>
              <Col xl={{ span: 6, order: 3 }} xs={{ order: 3 }} className="col">
                <div className="stepDescr">
                  <h5>Przypisz produkty lub usługi</h5>
                  <p>Dodaj opisy i grafiki przedstawiające produkty od jak najlepszej strony. Pamiętaj, że na tym etapie najważniejsze jest przykucie uwagi, nie musisz się więc skupiać na dokładnej specyfikacji.</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 1, offset: 4 }} className="col">
                <div className="stepArrow">
                  <img src={ArrowDownB} alt="" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 3, offset: 1 }} sm={{ span: 6, offset: 0, order: 1 }} xs={{ order: 2 }} className="col">
                <div className="stepImg">
                  <img src={StepC} alt="" />
                </div>
              </Col>
              <Col xl={{ span: 1, offset: 0 }} sm={{ span: 2, offset: 7, order: 2 }} xs={{ order: 1 }} className="col">
                <div className="stepNum">
                  3
                </div>
              </Col>
              <Col xl={{ span: 6, order: 3 }} xs={{ order: 3 }} className="col">
                <div className="stepDescr">
                  <h5>Doradzaj klientom</h5>
                  <p>Wykonaj prostą kalibrację, dzięki której Wirtualny Asystent będzie doradzał i komunikował klientom w Twoim imieniu. Suggestly będzie działać za Ciebie, angażując użytkowników o każdej porze dnia i nocy.</p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      {/* <section className="trustSection">
        <Container>
          <h4>Zaufane rozwiązanie</h4>
          <p>Sprawdź, jak Suggestly w praktyce angażuje odbiorcę</p>
          <div className="logosWrapper">
            <a href="https://codeebo.com">
              <img src={TrustLogo} alt="" />
            </a>
            <a href="https://codeebo.com">
              <img src={TrustLogo} alt="" />
            </a>
            <a href="https://codeebo.com">
              <img src={TrustLogo} alt="" />
            </a>
            <a href="https://codeebo.com">
              <img src={TrustLogo} alt="" />
            </a>
          </div>
        </Container>
      </section> */}

      <section className="trustSection">
        <Container>
          <h4>Zwiększ konwersję już dziś!</h4>
          <p>Projekty można bardzo szybko umieszczać na stronach internetowych w postaci widżetów.</p>
          {/* <div className="logosWrapper">
            <a href="https://codeebo.com">
              <img src={TrustLogo} alt="" />
            </a>
            <a href="https://codeebo.com">
              <img src={TrustLogo} alt="" />
            </a>
            <a href="https://codeebo.com">
              <img src={TrustLogo} alt="" />
            </a>
            <a href="https://codeebo.com">
              <img src={TrustLogo} alt="" />
            </a>
          </div> */}
        </Container>
      </section>

      <section ref={integracjaLink} className="togetherSection">
        <Container>
          <Row>
            <Col xl={{ span: 5, offset: 1, order: 1 }} sm={{ order: 1 }} xs={{ order: 2 }} className="col">
              <div className="togetherJumbDescr">
                <h3>Prosta integracja</h3>
                <p>Każdy projekt integruje się poprzez wstawienie krótkiego skryptu. Moduł działa zarówno na stronach statycznych jak i z cms (np. Wordpress). Doskonale sprawdza się również w systemach e-commerce.</p>
                <p><strong>Nie martw się kwestiami technicznymi!</strong> Skup się na realnych aspektach biznesowych.</p>
              </div>
            </Col>
            <Col xl={{ span: 5, order: 2 }} sm={{ order: 2 }} xs={{ order: 1 }} className="col">
              <img src={Relation} alt="" />
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 5, offset: 1 }} className="col arrowRollDown">
              <div>
                <img src={ArrowDownC} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="suggestMostImportantSection">
        <Container>
          <h4>Sugeruj to, co dla Ciebie najważniejsze</h4>
          <Row>
            <Col xl={{ span: 4 }} className="col colLeft">
              <img src={WantToBack} alt="" />
              <div className="descrWrapper">
                <h5>Spraw by chciało się powracać</h5>
                <p>
                  Projekty Suggestly analizują potrzeby odwiedzającego, a następnie wskazują mu możliwie najlepszy <strong>rezultat z linkiem</strong>, który przekierują jego uwagę w odpowiednie miejsce. Oszczędzając czas swoich klientów, dajesz wartość i budujesz zaufanie do Twojej marki.
              </p>
              </div>
            </Col>
            <Col xl={{ span: 4 }} className="col colMiddle">
              <img src={WorthContacts} alt="" />
              <div className="descrWrapper">
                <h5>Zdobądź wartościowe kontakty</h5>
                <p>
                  Każdy projekt ma na celu zaangażować i zainteresować potencjalnego klienta lub odbiorcę. Suggestly umożliwia <strong>zbieranie adresów email</strong> przed lub po wskazaniu rezultatu. Twoja lista mailingowa poszerzy się o osoby zainteresowane wskazanym produktem lub usługą.
              </p>
              </div>
            </Col>
            <Col xl={{ span: 4 }} className="col colRight">
              <img src={PositivMarketing} alt="" />
              <div className="descrWrapper">
                <h5>Twórz pozytywny marketing</h5>
                <p>
                  Suggestly jest również narzędziem, dzięki któremu użytkownik widzi oraz <strong>przetwarza komunikat</strong>, który chcesz zareklamować. Jednocześnie jest angażowany i dostaje za to nagrodę w postaci dopasowanej do jego potrzeb informacji.
              </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="actionSection">
        <Container>
          <Row>
            <Col xl={{ span: 5 }} className="col">
              <h4>Zacznij już dziś</h4>
              <p>
                Z Suggestly w parę minut stworzysz system wspomagania decyzji, który zwiększy konwersję Twojego biznesu online.<br />
                Nie musisz znać się na programowaniu!
              </p>
            </Col>
            <Col xl={{ span: 5, offset: 2 }} className="col">
              <h5>Nie przegap szansy, by zdobyć więcej kupujących.</h5>
              <div className="signUpWrapper">
                <Form onSubmit={(e: any) => e.preventDefault()}>
                  <FormGroup>
                    <Form.Control
                      placeholder="Twój email"
                      onChange={(e: any) => setEmailC(`${e.target.value}`)}
                      name="emailC"
                      type="text"
                      onKeyDown={handleKeyDownC}
                    />
                  </FormGroup>
                </Form>
                <div className="actionBtn actionBtn--dark" onClick={() => { dispatch(setSignUpEmail(emailC)) }}>
                  <Link to={RoutePath.SIGNUP}>
                    Zacznij za darmo
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section ref={CennikLink} className="freeSection">
        <Container>
          <Row>
            <Col xl={{ span: 5, order: 1 }} xs={{ span: 12, order: 1 }} className="col freeImg">
              <img src={FreeStart} alt="" />
            </Col>
            <Col xl={{ span: 7, order: 2 }} xs={{ span: 12, order: 2 }} className="col">
              <div className="box">
                <h4>Skorzystaj z darmowego projektu </h4>
                <p>Przekonaj się, jak Suggestly  pozytywnie angażuje Twoich odbiorców. </p>
                <p>Zrealizuj swój pomysł lub podziel się nim z nami, a my pomożemy Ci go zrealizować tak, by najlepiej spełniał Twoje oczekiwania.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="trialSection">
        <Container>
          <Row>
            <Col xl={{ span: 4 }} className="col">
              <div className="trialBox">
                <h5>Darmowy plan</h5>
                <div className="price">
                  0<span>zł/m</span>
                </div>
                <p>1 projekt</p>
                <p>do 1000 sugestii</p>
              </div>
            </Col>
            <Col xl={{ span: 2, offset: 1 }} className="col arrowImg">
              <img src={ArrowDownD} alt="" />
            </Col>

            <Col xl={{ span: 4, offset: 1 }} className="col">
              <div className="signUpWrapper">
                <h5>Nie martw się o czas triala!</h5>
                <p>W Suggestly nie ma tanich chwytów marketingowych. Możesz używać wersji testowej bez limitu czasowego!</p>
                <h6>Stwórz własnego Wirtualnego Asystenta już dzisiaj!</h6>
                <div className="formBox">
                  <Form onSubmit={(e: any) => e.preventDefault()}>
                    <FormGroup>
                      <Form.Control
                        placeholder="Twój email"
                        onChange={(e: any) => setEmailA(`${e.target.value}`)}
                        name="emailA"
                        type="text"
                        onKeyDown={handleKeyDownA}
                      />
                    </FormGroup>
                  </Form>
                  <div className="actionBtn actionBtn--orange" onClick={() => { dispatch(setSignUpEmail(emailA)) }}>
                    <Link to={RoutePath.SIGNUP}>Zacznij za darmo</Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <PrincingPlansLanding />

      <section ref={KontaktLink} className="contactSection">
        <div className="titleBox">
          <h4>Masz pytania?</h4>
          <p>Rozwiejemy Twoje wątpliwości i zmienimy je na wartość dla Ciebie! </p>
        </div>
        <Container>
          <Row>
            <Col xl={{ span: 4, offset: 2 }} className="col authorCol">
              <img src={MichalImg} alt="" />
              <p>Cześć, postaram się pomóc tak szybko jak to możliwe.</p>
              <p>Wierzę, że prawdziwa wartość jest możliwa do osięgnięcia, kiedy obie strony zyskują.</p>
              <p className="author">Michał Kowalczyk, <span>Twórca Suggestly</span></p>
            </Col>
            <Col xl={{ span: 4 }} className="col contactCol">
              <h5>Wyślij mi wiadomość</h5>
              <div className="formBox">
                <Form onSubmit={(e: any) => e.preventDefault()}>
                  <FormGroup>
                    <Form.Control
                      placeholder="Twoja wiadomość"
                      onChange={(e: any) => setContactMessage(`${e.target.value}`)}
                      name="contactMessage"
                      type="text"
                      value={contactMessage}
                      as="textarea"
                      rows={10}
                    />
                  </FormGroup>
                </Form>
              </div>
              <div className="formBox">
                <Form onSubmit={(e: any) => e.preventDefault()}>
                  <FormGroup>
                    <Form.Control
                      placeholder="Twój email"
                      onChange={(e: any) => setContactEmail(`${e.target.value}`)}
                      name="contactEmail"
                      value={contactEmail}
                      type="text"
                    />
                  </FormGroup>
                </Form>
                <div className={disableSendButton ? "actionBtn actionBtn--dark actionBtn--disabled" : "actionBtn actionBtn--dark"} onClick={sendMessageToSupport} style={{ pointerEvents: disableSendButton ? 'none' : 'auto' }}>
                  <span>Wyślij</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="footerActionSection">
        <Container>
          <Row>
            <Col xl={{ span: 2, offset: 1 }} className="col colHand">
              <img src={LeftHand} alt="" />
            </Col>
            <Col xl={{ span: 6 }} className="col">
              <h4>Nie czekaj. <br /> Przyciągnij do siebie klientów.</h4>
              <div className="signUpWrapper">
                <Form onSubmit={(e: any) => e.preventDefault()}>
                  <FormGroup>
                    <Form.Control
                      placeholder="Twój email"
                      onChange={(e: any) => setEmailB(`${e.target.value}`)}
                      name="emailB"
                      type="text"
                      onKeyDown={handleKeyDownB}
                    />
                  </FormGroup>
                </Form>
                <div className="actionBtn actionBtn--dark" onClick={() => { dispatch(setSignUpEmail(emailB)) }}>
                  <Link to={RoutePath.SIGNUP}>
                    Zacznij za darmo
                  </Link>
                </div>
              </div>
            </Col>
            <Col xl={{ span: 2 }} className="col colHand colHand--right">
              <img src={LeftHand} alt="" />
            </Col>
          </Row>
        </Container>
      </section>

      <footer>
        <Container>
          <Row>
            <Col xl={{ span: 4 }} className="col colLeft">
              Copyright 2020 Suggestly <br />
              Created by <a href="https://codeebo.pl" target="_blank" rel="noopener noreferrer">Codeebo</a>
            </Col>
            <Col xl={{ span: 4 }} className="col colMiddle">
              <a href="#" target="_blank" rel="noopener noreferrer"><img src={Twitter} alt="" /></a>
              <a href="#" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="" /></a>
              <a href="#" target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="" /></a>
            </Col>
            <Col xl={{ span: 4 }} className="col colRight">
              <Link to={{ pathname: RoutePath.RULES, state: { scrollTo: 'top' } }}>Polityka Prywatności</Link> <br />
              <Link to={{ pathname: RoutePath.RULES, state: { scrollTo: 'rules' } }}>Warunki Użytkowania</Link>
            </Col>
          </Row>
        </Container>
      </footer>

    </div>
  )
}

export default HomePage;