import React from "react";
import Helmet from "react-helmet";
import "./blog-page.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import NavbarLanding from "../../components/navbar-landing/navbar-landing";
import KawaButton from "./kawa-button";

const BlogPage: React.FC<any> = () => {
  return (
    <div className="signinup-page blog-page">
      <Helmet>
        <title>{'Suggestly | Jak wybrać dobrą kawę?'}</title>
      </Helmet>
      <NavbarLanding isSubNavHidden />
      <Row>
        <Col xl={{ span: 6, offset: 3 }}>
          <Card className="signInForm">
            <h5>Jak wybrać dobrą kawę?</h5>
            <KawaButton paramSuggId={"1d2b24e6-1b50-4a8c-5545-08d821b63eb7"}/>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default BlogPage;