import WagesStateTypes from "./wagesState.types";
import { WageDto, InputWageDto } from "../../dto/wageDto";

export const updateWage = (wageId: string, value: number) => {
  return {
    type: WagesStateTypes.UPDATE_WAGE,
    payload: { wageId: wageId, value: value }
  };
};

export const updateWageSuccess = (wageDto: WageDto) => {
  return {
    type: WagesStateTypes.UPDATE_WAGE_SUCCESS,
    payload: wageDto
  };
};

export const getAllWages = () => {
  return {
    type: WagesStateTypes.GET_ALL_CATEGORIESWAGES
  };
};

export const getAllWagesSuccess = (inputsWages: Array<InputWageDto>) => {
  return {
    type: WagesStateTypes.GET_ALL_CATEGORIESWAGES_SUCCESS,
    payload: inputsWages
  };
};

export const fakeRefreshSuccess = (flag : boolean) => {
  return {
    type: WagesStateTypes.FAKE_REFRESH_SUCCESS,
    payload: flag
  }
}