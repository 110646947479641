import WagesStateTypes from './wagesState.types';
import { InputWageDto } from '../../dto/wageDto';

const INITIAL_STATE = {
  inputsWages: [] as Array<InputWageDto>,
}

const wagesStateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case WagesStateTypes.GET_ALL_CATEGORIESWAGES_SUCCESS:
      return {
        ...state,
        inputsWages: action.payload
      }
    case WagesStateTypes.UPDATE_WAGE_SUCCESS:
      let indexOfCat = state.inputsWages.findIndex(x => x.inputId === action.payload.inputId);
      let indexOfProd = state.inputsWages[indexOfCat].outputs.findIndex(x => x.wages.findIndex(y => y.id === action.payload.id) !== -1);
      let indexOfWage = state.inputsWages[indexOfCat].outputs[indexOfProd].wages.findIndex(x => x.variantId === action.payload.variantId);

      let wagesUpdated = state.inputsWages;
      wagesUpdated[indexOfCat].outputs[indexOfProd].wages[indexOfWage] = action.payload;

      return {
        ...state,
        inputsWages: [...wagesUpdated]
      }
    case WagesStateTypes.FAKE_REFRESH_SUCCESS:
      let refreshedWages = state.inputsWages;
      if (action.payload)
        refreshedWages.push({} as InputWageDto);
      else
        refreshedWages.pop();
      return {
        ...state,
        inputsWages: refreshedWages
      }
    case WagesStateTypes.WAGES_CLEAR_STATE:
      return {
        ...state,
        inputsWages: [] as Array<InputWageDto>
      }
    default: {
      return state;
    }
  }
}

export default wagesStateReducer;