import React, { useState, useEffect } from "react";
import "./leads-panel.scss";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import MainSection from "../../../../components/main-section/main-section";
import { getAllLeads, deleteLead } from "../../../../redux/leadsState/leadsState.actions";
import { selectLeads } from "../../../../redux/leadsState/leadsState.selectors";
import { LeadDto } from "../../../../dto/leadDto";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InfiniteScroll from "../../../../components/infinite-scroll/infinite-scroll.component";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


const LeadsPanel: React.FC<any> = () => {

  const leads = useSelector(selectLeads, shallowEqual) as Array<LeadDto>

  const dispatch = useDispatch();
  const [firstLoad, setfirstLoad] = useState(true);

  useEffect(() => {
    getLeads(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (firstLoad && leads && leads.length > 0) {
      setfirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leads])

  const handleDelete = (leadId: string) => {
    dispatch(deleteLead(leadId));
  }

  const getLeads = (page: number) => {
    dispatch(getAllLeads(page, 100));
  }

  const whatIsGoingOnWithContacts = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">O co chodzi z tymi kontaktami?</Popover.Title>
      <Popover.Content>
        Suggestly umożliwia sprytne budowanie listy mailingowej.
        <br />
        <br />
        Przy dodawaniu każdej nowej sugestii, zostaniesz poproszony o określenie jej typu, 2 z nich umożliwiają dodatkowe zbieranie adresów email.
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      <Helmet>
        <title>{'Kontakty | Suggestly'}</title>
      </Helmet>
      <div className="kalibracja-panel">
        <Container fluid={false}>
          <MainSection
            title="Lista mailingowa"
            subtitle={<>W tym panelu znajduje się lista Twoich potencjalnych klientów, którzy zostawili swój email. Znajdziesz tu informacje takie jak:  data dołączenia, informacja o Sugestii, z której dokonano zapisu oraz czy dana osoba potwierdziła adres. <OverlayTrigger trigger={["hover", "focus"]} placement="bottom" overlay={whatIsGoingOnWithContacts}>
              <i className="fa fa-question-circle" aria-hidden="true" />
            </OverlayTrigger></>}
          >
          </MainSection>
          <div className="leads-wrapper" >
            {leads && leads.length > 0 ?
              <>
                <InfiniteScroll loadData={getLeads} itemsPerPage={100} currentItemsNumber={leads.length}>
                  {leads.map((lead: LeadDto, index: number) => {
                    return (
                      <Row className="lead-item" key={index}>
                        <Col xl={{ span: 1 }} className="number">
                          {index + 1}
                        </Col>
                        <Col xl={{ span: 3 }} className="email">
                          {lead.email}
                        </Col>
                        <Col xl={{ span: 2 }} className="datetime">
                          {lead.createdDate.toString().split('T')[0]}
                        </Col>
                        <Col xl={{ span: 3 }} className="outputName">
                          {lead.outputName}
                        </Col>
                        <Col xl={{ span: 2 }} className="isConfirmer">
                          {lead.isConfirmed ? "Potwierdzony" : "Niepotwierdzony"}
                        </Col>
                        <Col xl={{ span: 1 }} className="delete">
                          <div className="deleteItemWrapper" onClick={() => handleDelete(lead.id)}><i className="fa fa-trash" aria-hidden="true"></i></div>
                        </Col>
                      </Row>
                    )
                  })}
                </InfiniteScroll>
              </> :
              <div style={{ textAlign: "center" }}>
                Aktualnie nie posiadasz żadnych kontaktów.
              </div>
            }
          </div>
        </Container>
      </div>
    </>
  )
}

export default LeadsPanel;