import React, { useEffect, useRef } from "react";
import "./navbar-top.scss";
import Navbar from "react-bootstrap/Navbar";
import LogoImg from "./../../assets/logo.svg";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import { getCompanyInformations } from "../../redux/companyInformationsState/companyInformationsState.actions";
import { ApiRootPath } from "../../api/apiRootPath";
declare var suggestlyWidgetContainer: any;

const LiveButton: React.FC<any> = (props: any & RouteComponentProps) => {
  const widgetContainerRef = useRef<any>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanyInformations());

    let suggestlyRootWidgetElem = document.getElementById('suggestlyRootWidget');
    if (suggestlyRootWidgetElem) {
      suggestlyRootWidgetElem.remove();
      suggestlyRootWidgetElem = null;
    }

    const embedCode =
      `(function (w, d, s, o, f, js, fjs) {
      w['Suggestly-Widget'] = o; w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1;
      fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'suggestlyWidgetContainer', '${ApiRootPath.rootPath}script/widget.js'));
      suggestlyWidgetContainer('init', { targetElementId: 'suggestlyRootWidget', se_id: '${props.paramSuggId}' });`;

      let suggestlyScriptRoot = document.getElementById('suggestlyScriptRoot');
      if(suggestlyScriptRoot && props.paramSuggId){
        (suggestlyScriptRoot as HTMLScriptElement).text = embedCode;
        suggestlyWidgetContainer('init', { targetElementId: 'suggestlyRootWidget', se_id: `${props.paramSuggId}` });
      }

      return () => {
        let suggestlyScriptRoot = document.getElementById('suggestlyScriptRoot');
        if(suggestlyScriptRoot){
          (suggestlyScriptRoot as HTMLScriptElement).text = '';
        }
        var oldElem = document.getElementById('suggestlyWidgetContainer');
        if (oldElem) {
          oldElem.remove();
          oldElem = null;
        }
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paramSuggId])


  const handleLiveButton = () => {
    suggestlyWidgetContainer('openwidget', true);
  }

  return (
    <div className={props.isDisabled ? "navbarTop navbarTop--disabled " : "navbarTop"}>
      <Navbar bg="light">
        <Link to={RoutePath.HOME_PANEL}>
          <div className="brand">
            <img src={LogoImg} alt="LogoImg" />
          </div>
        </Link>
        {props.isPanelSwitch && props.isDisabled !== true ?
          <>
            <div ref={widgetContainerRef}></div>
            <div className="liveButton" onClick={handleLiveButton}>
              <i className="fa fa-eye" area-hidden="true"></i> &nbsp;Podgląd projektu
            </div>
          </>
          : null}
      </Navbar>
    </div >
  )
}

export default withRouter(LiveButton);