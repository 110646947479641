export class RoutePath {
  [key: string]: any;

  static EMBED_PANEL: string = '/a/panel/embed';
  static PYTANIA_PANEL: string = '/a/panel/inputs';
  static PRODUKTY_PANEL: string = '/a/panel/outputs';
  static KALIBRACJA_PANEL: string = '/a/panel/calibration';
  static PROJECTSETTINGS_PANEL: string = '/a/panel/project-settings';

  static LEADS_PANEL: string = '/a/panel/leads';
  static HOME_PANEL: string = '/a/panel';

  static SUBSCRIPTION_SETTINGS: string = '/a/settings/subscription';
  static PERSONAL_DATA_SETTINGS: string = '/a/settings/personal-data';
  static CONTACT_SETTINGS: string = '/a/settings/contact';
  static REALIZECOUPON_SETTINGS: string = '/a/settings/realize-coupon'
  static HOME_SETTINGS: string = '/a/settings';
  
  static HOME_PREVIEW: string = '/a/preview/:paramSuggestlyId';

  static HOME_PAYMENTCHECKOUT: string = '/a/payment-checkout';

  static AUTHORIZED: string = '/a';

  static CONFIRMEMAIL: string = '/confirm-email/:leadId'

  static SIGNUP: string = '/sign-up';
  static SIGNIN: string = '/sign-in';
  static RESETPASSWORD: string = '/reset-password';
  
  static BLOG: string = '/asystent/jak-wybrac-dobra-kawe';
  static RULES: string = '/warunki-uzytkowania';

  static HOME_start: string = '#start';
  static HOME_integracja: string = '#integracja';
  static HOME_cennik: string = '#cennik';
  static HOME_kontakt: string = '#kontakt';

  static HOME: string = '/';
}